import * as React from 'react'
import './styles.scss'

const Disclaimer = () => {
    return (
        <div className="c-disclaimer">
            <span className="c-disclaimer__assurance">
                Safe | Secure | Privacy Protected
            </span>

            <p className="c-disclaimer__wording">
                By selecting 'Compare Quotes', you agree to be contacted by
                email and telephone by an FCA authorised insurance firm and
                confirm that you have read and agreed to our{' '}
                <a href="/terms-conditions/">Terms &amp; Conditions</a> and{' '}
                <a href="/privacy-policy">Privacy Policy</a>. Gift voucher T&Cs
                apply, please see our{' '}
                <a href="https://www.protectline.co.uk/refer-a-friend">
                    Refer a Friend scheme
                </a>
                .
            </p>

            <p className="c-disclaimer__asterisks">
                * Example: Life insurance starting from &pound;5 a month for
                &pound;100,000 of cover for 20 years for a 30 year old
                non-smoker, based on perfect health.
            </p>
        </div>
    )
}

export default Disclaimer
